import React from "react";
import { graphql, StaticQuery } from "gatsby";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import LangSwitcher from "./LangSwitcher"

const Menu = props => (
        <div id="main-menu" className="main-menu">
          <ul>
            {props.data.site.siteMetadata.menuLinks.map((link,i) => (
              <li key={i}>
                <Link to={link.link}><FormattedMessage id={link.name} /></Link>
              </li>
            ))}
            {props.path.includes("services/") ||
                props.path.includes("szolgaltatasok/") ||
                props.path.includes("projects/") ||
                props.path.includes("projektek/") ||
                props.path.includes("terms/") ||
                props.path.includes("customer/") ? null : (
                  <li key={"lang"}><LangSwitcher text={true} /></li>
              )}
          </ul>
        </div>
  );

export default props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <Menu path={props.path} data={data} />}
  />
);
