import React from "react";
import { graphql, StaticQuery } from "gatsby";

import LangSwitcher from "./LangSwitcher";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const Footer = (props) => {
	return (
		<div className="footer-strip">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="footer">
							<div className="footer-left">
								<h3 className="footer-title">
									{props.data.site.siteMetadata.title}
								</h3>

							</div>

							<div className="footer-menu mt-2 mt-md-0">
								<span className="footer-link ml-0 ml-md-2">
									<Link to="/terms/adatvedelem">
										<FormattedMessage id="Data_protection" />
									</Link>
								</span>
								<span className="footer-link ml-0 ml-md-2">
									<Link to="/terms/szerzodes">
										<FormattedMessage id="T&C" />
									</Link>
								</span>
								{props.path.includes("services/") ||
									props.path.includes("szolgaltatasok/") ||
									props.path.includes("projects/") ||
									props.path.includes("projektek/") ||
									props.path.includes("terms/") ||
									props.path.includes("customer/") ? null : (
									<span className="footer-link ml-0 ml-md-1"><LangSwitcher /></span>
								)}
								<span className="footer-link copyright">
									© {new Date().getFullYear()}{" "}
									{props.data.site.siteMetadata.title}
								</span>
							</div>



						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default props => (
	<StaticQuery
		query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/terms/" }
            frontmatter: { type: { eq: "data" } }
          }
        ) {
          edges {
            node {
              frontmatter {
                path
                lang
                type
              }
            }
          }
        }
      }
    `}
		render={data => <Footer path={props.path} data={data} {...props} />}
	/>
);
