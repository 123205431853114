import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

import nkfi from "../images/logos/nkfi.jpg";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const SubFooter = props => (
	<div className="sub-footer-strip">
		<div className="container">
			<div className="row my-3">
				<div className="col-md-6 col-12">

					<Link to="/tamogatasok/nkfi">
						<img src={nkfi} className="footer-logo" />
					</Link>

				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="sub-footer">
						<ul>
							<li>
								<strong><FormattedMessage id="Phone" />: </strong>
								{props.data.site.siteMetadata.contact.phone}
							</li>
							<li>
								<strong><FormattedMessage id="Email" />: </strong>{" "}
								<a
									href={`mailto:${props.data.site.siteMetadata.contact.email}`}
								>
									{props.data.site.siteMetadata.contact.email}
								</a>
							</li>
						</ul>
						<ul>
							<li>
								<a href="https://www.wattmanager.hu">www.wattmanager.hu</a>
							</li>
							<li>
								<a
									target="_blank"
									rel="noopener"
									href={props.data.site.siteMetadata.contact.facebook}
								>
									<FaFacebookSquare />
								</a>
								<a
									target="_blank"
									rel="noopener"
									href={props.data.site.siteMetadata.contact.linkedin}
								>
									<FaLinkedin />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default props => (
	<StaticQuery
		query={graphql`
      query {
        site {
          siteMetadata {
            contact {
              email
              phone
              facebook
              linkedin
            }
          }
        }
      }
    `}
		render={data => <SubFooter data={data} />}
	/>
);
