import React from "react";

import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

import Flag from "react-world-flags";

const languageName = {
  en: "English",
  hu: "Magyar"
}

const languageBig = {
  en: "EN",
  hu: "HU"
}

const flags = {
  en: "gbr",
  hu: "hun"
}

const LangSwitcher = ({text}) =>
    <IntlContextConsumer>
         {({ languages, language: currentLocale }) =>
          languages.map(language =>
          currentLocale !== language ? (
              <a
              className="langswitch-link"
              key={language}
              onClick={() => changeLocale(language)}
              style={{
                margin: 10,
                textDecoration: `underline`,
                cursor: `pointer`,
              }}
              >
                {text ? (
                  <span>{languageBig[language]}</span>
                ) : (
                  <Flag code={flags[language]} alt={languageName[language]} height="12" />
                )}
                
            </a>
            ) : null 
          )
        }
      </IntlContextConsumer>

export default LangSwitcher
