import React from "react";
import { StaticQuery, graphql } from "gatsby";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SubFooter from "../components/SubFooter";

import "rsuite/dist/rsuite.min.css";
import "../scss/style.scss";

import { useIntl, Link } from "gatsby-plugin-intl";
import CookieConsent, { Cookies } from "react-cookie-consent";

import PropTypes from "prop-types";

const Layout = (props) => {
	const intl = useIntl();
	return (
		<>
			<SEO />
			{/*  
        <Head hreflang>
          <title>{t('Gatsby Starter I18next')}</title>
          <meta name="description" content="Sample" />
          <meta name="keywords" content="gatsby, react, wapps, i18next" />
        </Head>
      */}
			<div
				className={` ${props.deferGoup ? `` : `transition-fade`} page${props.bodyClass ? ` ${props.bodyClass}` : ""
					}`}
			>
				<div id='wrapper' className='wrapper'>
					<Header path={props.path} />
					{props.children}
				</div>
				<Footer path={props.path} />
				<SubFooter />

				<CookieConsent
					acceptOnScroll={true}
					disableStyles={true}
					buttonClasses='btn-coockie'
					onAccept={() => {
						Cookies.set("gatsby-gdpr-google-analytics", true, { expires: 365 });
						Cookies.set("gatsby-gdpr-google-tagmanager", true, {
							expires: 365,
						});
						Cookies.set("gatsby-gdpr-facebook-pixel", true, { expires: 365 });
					}}
					buttonText={intl.formatMessage({ id: "accept" })}
					declineButtonText={intl.formatMessage({ id: "decline" })}
				>
					{intl.formatMessage({ id: "cookies" })}
					<br />
					<Link to='/terms/adatvedelem'>
						{intl.formatMessage({ id: "read_more" })}
					</Link>
				</CookieConsent>
			</div>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
