import React from "react";
import Menu from "./Menu";
import Hamburger from "./Hamburger";
import logo from "../images/wattman_logo.webp";
import logoMobile from "../images/wattman_mobile.webp";
import MenuMobile from "./MenuMobile";


import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false
    };
  }

  toggleMenu = menuActive => {
    this.setState(prevState => ({
      menuActive: !prevState.menuActive
    }));
  };

  render() {
    return (
          <div className="header sticky">
            <div className="container">
              <div className="logo">
                <Link to="/">
                  <img alt="Figurit Homepage" src={logo} />
                </Link>
              </div>
              <div className="logo-mobile">
                <Link to="/">
                  <img alt="Figurit Homepage" src={logoMobile} />
                </Link>
              </div>
              <MenuMobile path={this.props.path} active={this.state.menuActive} />
              <Menu path={this.props.path} />
              <Hamburger toggleMenu={this.toggleMenu} />
            </div>
          </div>
    );
  }
}

export default Header;
