import React from "react";
import { graphql, StaticQuery } from "gatsby";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import LangSwitcher from "./LangSwitcher";


const MenuMobile = props => (
  <div id="main-menu-mobile" className={`main-menu-mobile ${props.active ? "open" : ""}`}>
    <ul>
      {props.data.site.siteMetadata.menuLinks.map(link => (
        <li key={link.name}>
          <Link to={link.link}><FormattedMessage id={link.name} /></Link>
        </li>
      ))}
    </ul>
    <div className="mobile_lang">
      {props.path.includes("services/") ||
        props.path.includes("szolgaltatasok/") ||
        props.path.includes("projects/") ||
        props.path.includes("projektek/") ||
        props.path.includes("customer/") ? null : (
          <LangSwitcher />
      )}
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query MenuMobileQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <MenuMobile path={props.path} active={props.active} data={data} />}
  />
);
